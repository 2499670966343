import { InfoCircleFilled } from '@ant-design/icons';
import { Form, Select, Tooltip } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { REDACTION_API, createMC } from '../../../services';
import CustomButton from '../../Button';
import customTost from '../../Notification';
import { RedactionWord } from './TextEditor';
import RedactionModal from './redactionModal';
import './styles.scss';

const { Option } = Select;

// create a list of words from array of object
const getFormatedTags = (lis) => {
	return lis.map((tag) => tag.label);
};

// check if commit button should be disabled
export const isRedactBtnDisable = (isRedactRady, isbtnDisabled, isErrInPass, isErrInBlock) => {
	// check if isRedacRady is true but there are no words in editor
	if (!isbtnDisabled && isRedactRady) {
		return !isbtnDisabled;
	} else if (isErrInPass || isErrInBlock) {
		return true;
	} else {
		return !isRedactRady;
	}
};

// get signed url from minio bucket and return singned url
export const getSignedUrl = async (envars, imageName, bucket = 'forwarded') => {
	try {
		const mc = createMC(envars);

		const promise = () => {
			return new Promise(function (resolve) {
				const url = mc.presignedGetObject(bucket, imageName);
				url
					.then((resp) => {
						resolve({
							screenshotKey: imageName,
							url: resp,
							error: false,
						});
					})
					.catch(() => {
						resolve({
							screenshotKey: imageName,
							url: null,
							error: true,
						});
					});
			});
		};

		const url = await promise();
		if (url.error) {
			customTost({
				type: 'error',
				message: 'Please check your first bucket configuration.',
			});
		}
		return url;
	} catch (err) {
		return '';
	}
};

// download all the editor and other fileds data in json format
export const downloadJson = ({ obj }) => {
	const exportName = 'redaction'; // (Math.random() + 1).toString(36).substring(7)

	const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(obj));
	const downloadAnchorNode = document.createElement('a');
	downloadAnchorNode.setAttribute('href', dataStr);
	downloadAnchorNode.setAttribute('download', exportName + '.json');
	document.body.appendChild(downloadAnchorNode); // required for firefox
	downloadAnchorNode.click();
	downloadAnchorNode.remove();
};

let cancelApiToken;

const Redaction = (props) => {
	const form = props.form;
	const {
		setIsLoading,
		getSelectedImg,
		handleRedactionProcess,
		isLocLoading,
		setLocLoading,
		currentRegex,
		setCurrentWords,
		setRedactionSettings,
		redactionSettings,
		renderBoundingBox,
		setOcr,
		setRedactionResult,
		initialValues,
		setPreviewView,
	} = props;
	const { adminUserFlag, envars } = useSelector((store) => store.storeProps);
	const [pass, setPass] = useState({ addList: [], removeList: [] });
	const [block, setBlock] = useState({ addList: [], removeList: [] });
	const [isModalOpen, setModelOpen] = useState(false);
	const [isRedactRady, setRedactRady] = useState(false);
	const [isRedactFormEnabel, setRedactForm] = useState(false);
	const [refetchList, setRefetchList] = useState(0);
	const [forceClear, setForceClear] = useState(0);
	const [isErrInPass, setErrInPass] = useState(false);
	const [isErrInBlock, setErrInBlock] = useState(false);

	// check if clear and preview button should be disabled
	const isbtnDisabled =
		pass.addList.length ||
		pass.removeList.length ||
		block.addList.length ||
		block.removeList.length;

	useEffect(() => {
		// reset masked image if there is no words in pass/block list
		if (!isbtnDisabled) {
			handleRedactionProcess('');
			setRedactForm(false);
			setRedactRady(false);
			setOcr([]);
			setRedactionResult([]);
		}
	}, [isbtnDisabled]);

	useEffect(() => {
		if (form?.setFieldsValue && initialValues.length) {
			const cleaning = initialValues.find((i) => i.name === 'cleaning');
			const symspell = initialValues.find((i) => i.name === 'symspell__edit_distance');
			form.setFieldsValue({
				// set defautl values in form if not present in res
				cleaning: cleaning?.value || 'false',
				symspell__edit_distance: symspell?.value || '0',
			});
		}
	}, [initialValues]);

	const getFormatedWords = () => {
		const addPassList = getFormatedTags(pass.addList);
		const removePassList = getFormatedTags(pass.removeList);
		const addBlockList = getFormatedTags(block.addList);
		const removeBlockList = getFormatedTags(block.removeList);
		const payload = {
			pass: { addList: addPassList, removeList: removePassList },
			block: { addList: addBlockList, removeList: removeBlockList },
		};
		return payload;
	};

	useEffect(() => {
		setCurrentWords(getFormatedWords());
	}, [pass, block]);

	const handleSubmit = async (values) => {
		try {
			setPreviewView({ state: false, data: '', singlePreview: false });
			if (values?.cleaning && values?.symspell__edit_distance) {
				const payload = getFormatedWords();
				setCurrentWords(payload);

				// get current image id from carousel
				const imgId = getSelectedImg();
				const imgKey = imgId.split('.');

				let parms = {
					...payload,
					UUID: imgKey[0],
					cleaning: JSON.parse(values.cleaning),
					symspell__edit_distance: JSON.parse(values.symspell__edit_distance),
					custom_regex: currentRegex ? [currentRegex] : [],
					edit_regex: '',
				};

				// cancel API if there are to many requests
				if (typeof cancelApiToken !== typeof undefined) {
					await cancelApiToken.cancel('Operation canceled due to new request.');
				}
				cancelApiToken = axios.CancelToken.source();
				if (isbtnDisabled && isRedactFormEnabel) {
					setIsLoading(true);
					setLocLoading(true);
					const res = await REDACTION_API(parms, {
						cancelToken: cancelApiToken.token,
					});
					const { data } = res;
					if (res.status === 200) {
						const maskedImg = await getSignedUrl(envars, `${imgKey[0]}_redacted.${imgKey[1]}`);
						handleRedactionProcess(maskedImg);
						setRedactRady(true);
						setTimeout(() => {
							renderBoundingBox(res?.data?.redacted_ocr);
							setOcr(res?.data?.redacted_ocr);
							customTost({
								type: 'success',
								message: data?.message ? data.message : 'Redacted Successfully!.',
							});
						}, 2000);
					} else {
						customTost({
							type: 'error',
							message: data?.message ? data.message : 'Internal server error.',
						});
						handleRedactionProcess('');
					}

					// setIsLoading(false);
					// setLocLoading(false);
				}
			}
		} catch (err) {
			if (err.message !== 'Operation canceled due to new request.') {
				setIsLoading(false);
				setLocLoading(false);
				handleRedactionProcess('');

				customTost({
					type: 'error',
					message: err?.response?.data?.message || 'Internal server error.',
				});
			}
		}
	};

	// reset test editor
	const clearPassLists = () => {
		setPass({ addList: [], removeList: [] });
		setBlock({ addList: [], removeList: [] });
		setForceClear(Math.random());
	};

	// export/download json file in users system
	const exportSettings = (e) => {
		try {
			e.stopPropagation();
			const obj = {
				pass: pass,
				block: block,
				cleaning: form.getFieldValue('cleaning'),
				symspell__edit_distance: form.getFieldValue('symspell__edit_distance'),
				// isPass: form.getFieldValue('isPass'),
			};
			downloadJson({ obj });

			customTost({
				type: 'success',
				message: 'Settings exported successfully!',
			});
		} catch (err) {
			customTost({
				type: 'error',
				message: 'Something went wrong',
			});
		}
	};

	// import json file and display data in text editor and other fiels
	const importSettings = (event) => {
		try {
			const filetType = event.target.files[0].type;

			if (filetType !== 'application/json') {
				customTost({
					type: 'error',
					message: 'Invalid file. It should be a valid JSON.',
				});
				return;
			}
			const fileReader = new FileReader();
			fileReader.readAsText(event.target.files[0], 'UTF-8');

			fileReader.onload = (e) => {
				const result = e.target.result ? JSON.parse(e.target.result) : {};

				if (result.pass && result.block) {
					setPass({
						addList: result.pass?.addList || [],
						removeList: result.pass?.removeList || [],
					});
					setBlock({
						addList: result.block?.addList || [],
						removeList: result.block?.removeList || [],
					});
					form?.setFieldsValue({
						cleaning: result?.cleaning || false,
						symspell__edit_distance: result?.symspell__edit_distance || '0',
						// isPass: result?.isPass || false,
					});
					customTost({
						type: 'success',
						message: 'Settings imported successfully!',
					});
					return;
				}
				customTost({
					type: 'error',
					message: "Invalid 'data' field. 'data' should be a valid JSON.",
				});
			};
		} catch (err) {
			customTost({
				type: 'error',
				message: 'Something went wrong',
			});
		}
	};

	const onSettingsChange = (e) => {
		let settings = e.symspell__edit_distance
			? { ...redactionSettings, symspell__edit_distance: JSON.parse(e.symspell__edit_distance) }
			: { ...redactionSettings, cleaning: JSON.parse(e.cleaning) };
		setRedactionSettings(settings);
	};

	return (
		<>
			<input
				type='file'
				name='file'
				accept='.json'
				id='file-input'
				onChange={importSettings}
				onClick={(event) => (event.target.value = null)}
			/>
			<div className='readaction-wrapper'>
				<div className='import-btns'>
					<Tooltip placement='top' title='Import Settings'>
						<div>
							<CustomButton
								className='gray-btn imp-button'
								htmlFor='file-input'
								onClick={(e) => {
									e.stopPropagation();
									document.getElementById('file-input')?.click();
								}}
							>
								{/* {<UploadOutlined />} */}
								<img src='./import_icon.png' alt='Import image icon' />
							</CustomButton>
						</div>
					</Tooltip>
					<Tooltip placement='top' title='Export Settings'>
						<div>
							<CustomButton className='gray-btn exp' onClick={exportSettings}>
								{/* {<DownloadOutlined />} */}
								<img src='./export_icon.png' alt='Export image icon' />
							</CustomButton>
						</div>
					</Tooltip>
				</div>
				<div className='wordsForm'>
					<Form
						className='form-real'
						form={form}
						layout='vertical'
						initialValues={{ symspell__edit_distance: '0', cleaning: 'false' }}
						onFinish={handleSubmit}
						requiredMark={false}
					>
						<div className='readaction-body'>
							<div className='list-container'>
								<RedactionWord
									title='Pass List'
									fileName='pass-list.txt'
									envars={envars}
									adminUserFlag={adminUserFlag}
									editorId='words_wrapper-pass'
									addList={pass.addList}
									setAddList={(v) => setPass({ ...pass, addList: v })}
									removeList={pass.removeList}
									reFetch={refetchList}
									setAnyErr={setErrInPass}
									forceClear={forceClear}
									setRemoveList={(v) => setPass({ ...pass, removeList: v })}
								/>
								<div className='divider' />
								<RedactionWord
									title='Block List'
									fileName='block-list.txt'
									envars={envars}
									adminUserFlag={adminUserFlag}
									editorId='words_wrapper-block'
									addList={block.addList}
									setAddList={(v) => setBlock({ ...block, addList: v })}
									removeList={block.removeList}
									reFetch={refetchList}
									setAnyErr={setErrInBlock}
									forceClear={forceClear}
									setRemoveList={(v) => setBlock({ ...block, removeList: v })}
								/>
							</div>
							<div className='list-container dual-container'>
								<div className='dual-row'>
									<Form.Item
										className='form-field'
										label={<>Fuzzy Matching</>}
										tooltip={{
											title: 'Disable exact match',
											icon: <InfoCircleFilled />,
										}}
										name='symspell__edit_distance'
										rules={[{ required: true, message: 'Required' }]}
									>
										<Select
											className='fuzzy'
											onChange={(e) => onSettingsChange({ symspell__edit_distance: e })}
										>
											<Option value='1'>true</Option>
											<Option value='0'>false</Option>
										</Select>
									</Form.Item>
									<Form.Item
										className='form-field'
										label={<>Cleaning</>}
										tooltip={{
											title:
												'If set to true, it will remove all non-alphabet characters from a detected word before comparing it to the words in the dictionaries.',
											icon: <InfoCircleFilled />,
										}}
										name='cleaning'
										rules={[{ required: true, message: 'Required' }]}
									>
										<Select className='clean-r' onChange={(e) => onSettingsChange({ cleaning: e })}>
											<Option value='true'>true</Option>
											<Option value='false'>false</Option>
										</Select>
									</Form.Item>
								</div>

								{/* <div className='dual-row'>
                    <Form.Item
                      className='form-field'
                      label={<>With pass/block list</>}
                      name='isPass'
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <Select>
                        <Option value='true'>true</Option>
                        <Option value='false'>false</Option>
                      </Select>
                    </Form.Item>
                  </div> */}
							</div>

							<div className='btn_add'>
								<div className='other-btns'>
									<div className='btn-set1'>
										<CustomButton
											className='clear-btn reset'
											onClick={clearPassLists}
											isDisabled={isLocLoading || !isbtnDisabled}
										>
											{'Clear'}
										</CustomButton>

										<CustomButton
											className='gray-btn preview'
											onClick={() => setRedactForm(true)}
											type='primary'
											htmlType='submit'
											isLoading={isLocLoading}
											isDisabled={!isbtnDisabled || isErrInPass || isErrInBlock}
										>
											{'Preview'}
										</CustomButton>
									</div>

									{adminUserFlag && (
										<div className='btn-set2'>
											<div className='dvider' />
											<CustomButton
												type='primary'
												className='commit'
												isDisabled={isRedactBtnDisable(
													isRedactRady,
													isbtnDisabled,
													isErrInPass,
													isErrInBlock,
												)}
												onClick={() => setModelOpen(true)}
											>
												{'Commit'}
											</CustomButton>
										</div>
									)}
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div>
			<RedactionModal
				isModalOpen={isModalOpen}
				setModelOpen={setModelOpen}
				form={form}
				envars={envars}
				passList={{
					addList: getFormatedTags(pass.addList),
					removeList: getFormatedTags(pass.removeList),
				}}
				blockList={{
					addList: getFormatedTags(block.addList),
					removeList: getFormatedTags(block.removeList),
				}}
				setRefetchList={() => setRefetchList(refetchList + 1)}
				clearPassLists={clearPassLists}
				setLocLoading={setIsLoading}
				setRedactionResult={setRedactionResult}
				initialValues={initialValues}
			/>
		</>
	);
};

Redaction.propTypes = {
	form: PropTypes.any,
	setIsLoading: PropTypes.func,
	getSelectedImg: PropTypes.func,
	handleRedactionProcess: PropTypes.func,
	setChange: PropTypes.func,
	isLocLoading: PropTypes.bool,
	setLocLoading: PropTypes.func,
	currentRegex: PropTypes.any,
	setCurrentWords: PropTypes.func,
	setRedactionSettings: PropTypes.func,
	redactionSettings: PropTypes.any,
	renderBoundingBox: PropTypes.func,
	setOcr: PropTypes.func,
	setRedactionResult: PropTypes.func,
	initialValues: PropTypes.any,
	setPreviewView: PropTypes.func,
};

export default Redaction;
