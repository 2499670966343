import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import CustomButton from '../../Button';
import CustomModal from '../../Modal';

const RegexModel = (props) => {
	const {
		isModalOpen,
		setIsModelOpen,
		deleteView,
		setLoading,
		setPreviewLoading,
		regexFileData,
		updateRegexData,
		editView,
		formValues,
	} = props;

	// handle regex commit action and upload new data into minio bucket
	const handleRegexCommit = () => {
		try {
			setLoading(true);
			setPreviewLoading(true);
			let fileStream = '';
			let newValues;
			if (editView?.state) {
				newValues = regexFileData.map((val) => {
					if (
						val.regex === editView?.data?.regex &&
						val.description === editView?.data?.description
					) {
						return formValues;
					} else {
						return val;
					}
				});
				fileStream = newValues;
			} else {
				newValues = [{ ...formValues }];
				fileStream = regexFileData.length ? [...newValues, ...regexFileData] : newValues;
			}
			if (fileStream) {
				updateRegexData(fileStream, 'update');
			}
		} catch (err) {
			setPreviewLoading(false);
			setLoading(false);
		}
	};

	// delete regex item and update regex-data list
	const deleteRow = () => {
		try {
			setLoading(true);
			let rowIndex;
			regexFileData.find((val, i) => {
				if (
					val.regex === deleteView?.data?.regex &&
					val.description === deleteView?.data?.description
				) {
					rowIndex = i;
				}
			});
			regexFileData.splice(rowIndex, 1);
			updateRegexData(regexFileData, 'delete');
		} catch (err) {
			setLoading(false);
		}
	};

	return (
		<CustomModal
			isOpen={isModalOpen}
			className='successModal modal_wrapper'
			onClose={() => setIsModelOpen(false)}
			footer={[
				<CustomButton onClick={() => setIsModelOpen(false)} key='btnClose' id='btnClose'>
					{'No, Cancel'}
				</CustomButton>,

				<CustomButton
					className='r-commit'
					onClick={() => {
						deleteView?.state ? deleteRow() : handleRegexCommit();
						setIsModelOpen(false);
					}}
					type='primary'
					key='btndownload'
				>
					Yes, {deleteView?.state ? 'Delete' : 'Commit'}
				</CustomButton>,
			]}
		>
			<div className='success_modal'>
				<ExclamationCircleOutlined className='success-modal-icon' />
				<div className='success_modal_header'>Are you sure?</div>
				{!deleteView?.state && (
					<div className='success_modal_sub'>
						All redaction changes will be saved and will be used in the next newly masked images.
					</div>
				)}
			</div>
		</CustomModal>
	);
};

RegexModel.propTypes = {
	isModalOpen: PropTypes.bool,
	setIsModelOpen: PropTypes.func,
	deleteView: PropTypes.object,
	setLoading: PropTypes.func,
	setPreviewLoading: PropTypes.func,
	regexFileData: PropTypes.any,
	updateRegexData: PropTypes.func,
	editView: PropTypes.object,
	formValues: PropTypes.object,
};

export default RegexModel;
